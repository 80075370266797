import React from 'react';
import Team1 from './YashTeam.jpeg';
import Team2 from './Joel.jpeg';
import Team3 from './anjana.jpeg';
import Team4 from './srashti.jpeg';

const TeamSection = () => {
  return (
    <section className="text-gray-600 body-font bg-white">
      <div className="container px-4 sm:px-8 py-16 mx-auto">
        <div className="flex flex-col text-center w-full mb-10">
          <h1 className="text-xl sm:text-2xl lg:text-3xl font-medium title-font mb-4 text-black">OUR TEAM</h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Meet the team behind UniSOP. We are a group of driven individuals from <strong>IIT Bombay</strong> and <strong>NIT Warangal</strong> with an aim to make your journey to grad school seamless.
          </p>
        </div>
        <div className="flex flex-wrap justify-center -m-4">
          <div className="p-4 lg:w-1/4 md:w-1/2">
            <div className="h-full flex flex-col items-center text-center">
              <img alt="team" className="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src={Team1} />
              <div className="w-full">
                <h2 className="title-font font-medium text-lg text-black">Yash Somkuwar</h2>
                <h3 className="text-gray-500 mb-3">Mentor, Co-Founder</h3>
              </div>
            </div>
          </div>
          <div className="p-4 lg:w-1/4 md:w-1/2">
            <div className="h-full flex flex-col items-center text-center">
              <img alt="team" className="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src={Team2} />
              <div className="w-full">
                <h2 className="title-font font-medium text-lg text-black">Joel Jacob</h2>
                <h3 className="text-gray-500 mb-3">Mentor, Co-Founder</h3>
              </div>
            </div>
          </div>
          <div className="p-4 lg:w-1/4 md:w-1/2">
            <div className="h-full flex flex-col items-center text-center">
              <img alt="team" className="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src={Team3} />
              <div className="w-full">
                <h2 className="title-font font-medium text-lg text-black">Anjana Tumuluri</h2>
                <h3 className="text-gray-500 mb-3">Marketing Head</h3>
              </div>
            </div>
          </div>
          <div className="p-4 lg:w-1/4 md:w-1/2">
            <div className="h-full flex flex-col items-center text-center">
              <img alt="team" className="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src={Team4} />
              <div className="w-full">
                <h2 className="title-font font-medium text-lg text-black">Srashti Yadav</h2>
                <h3 className="text-gray-500 mb-3">Design Head</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
