import { useState } from 'react';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Logo from "./Logo";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-white">
      <div className="mx-auto flex h-16 max-w-full items-center gap-8 px-4 sm:px-6 lg:px-8">
        <Link className="block text-teal-600" to="">
          <span className="sr-only">Home</span>
          <Logo />
        </Link>

        <div className="flex items-center justify-end md:justify-between w-full">
          <nav aria-label="Global" className="hidden md:block">
            <ul className="flex items-center gap-6 text-base">
              <li>
                <Link className="text-black transition hover:text-blue-600" to="about-us">About</Link>
              </li>

              <li>
                <Link className="text-black transition hover:text-blue-600" to="/curated-colleges">Curated College Directory</Link>
              </li>

              <li>
                <Link className="text-black transition hover:text-blue-600" to="/qs-ranking">QS Ranking List</Link>
              </li>
            </ul>
          </nav>

          <div className="flex items-center gap-4">
            <div className="sm:flex sm:gap-4 hidden">
              <a
                className="block rounded-md bg-orange-500 px-5 py-2.5 text-sm font-bold text-white transition hover:bg-blue-700"
                href="https://calendly.com/unisop/30min"
              >
                Book a Session
              </a>

              <a
                className="hidden rounded-md bg-gray-100 px-5 py-2.5 text-sm font-bold text-orange-500 transition hover:text-blue-600/75 sm:block"
                href="mailto:unisophelp@gmail.com?subject=Booking%20a%20Free%20Session&body=Hello%20Unisop,%0D%0A%0D%0AI'm%20interested%20in%20booking%20a%20free%20session.%0D%0A%0D%0AThank%20you!"
              >
                Contact US
              </a>
            </div>

            <button
              className="block rounded bg-gray-100 p-2.5 text-black transition hover:text-black/75 md:hidden"
              onClick={toggleMenu}
            >
              <span className="sr-only">Toggle menu</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Side menu */}
      {isMenuOpen && (
  <div className="md:hidden fixed inset-0 bg-gray-800 bg-opacity-75 z-50">
    <div className="flex flex-col h-full w-64 bg-white shadow-lg">
      <button className="text-right mt-4 mr-4" onClick={toggleMenu}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <div className="flex flex-col gap-4 items-start ml-4 mt-8">
        <Link className="text-black hover:text-blue-600" to="">
          <div className="flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-teal-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
            </svg>
            <span className="font-bold">Home</span>
          </div>
        </Link>
        <Link className="text-black hover:text-blue-600" to="/about-us">
          <div className="flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-teal-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <span className="font-bold">About</span>
          </div>
        </Link>
        <Link className="text-black hover:text-blue-600" to="/curated-colleges">
          <div className="flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-teal-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <span className="font-bold">Curated College Directory</span>
          </div>
        </Link>
        <Link className="text-black hover:text-blue-600" to="/qs-ranking">
          <div className="flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-teal-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <span className="font-bold">QS Ranking List</span>
          </div>
        </Link>
        <a className="text-black hover:text-blue-600" href="https://calendly.com/unisop/30min">
          <div className="flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-teal-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <span className="font-bold">Book a Session</span>
          </div>
        </a>
        <a className="text-black hover:text-blue-600" href="mailto:unisophelp@gmail.com?subject=Booking%20a%20Free%20Session&body=Hello%20Unisop,%0D%0A%0D%0AI'm%20interested%20in%20booking%20a%20free%20session.%0D%0A%0D%0AThank%20you!" target="_blank">
  <div className="flex items-center gap-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5 text-teal-600"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
    </svg>
    <span className="font-bold">Contact Us</span>
  </div>
</a>


      </div>
    </div>
  </div>
)}

    </header>
  );
};

export default Header;
